<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 pt-5 pb-0">
        <h3 class="card-title font-weight-bolder text-dark">Lessors</h3>
        <div class="card-toolbar">
          <!--begin::Button-->
          <router-link
            to="/lessors/new"
            class="btn btn-primary font-weight-bolder"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg src="media/svg/icons/Electric/Outlet.svg" /> </span
            >Add New
          </router-link>
        </div>
      </div>

      <div class="card-body">
        <div v-if="!isLoading" class="table-responsive mb-0 pb-0">
          <table
            class="table table-head-custom table-vertical-center table-head-bg"
          >
            <thead>
              <tr class="text-uppercase">
                <th class="px-6">Name</th>
                <th>Address</th>
                <th>Oib</th>
                <th>@</th>
                <th class="text-right pr-6">Options</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(lessor, key) in lessors" :key="lessor.id">
                <td :class="{ 'border-top-0': key === 0 }" class="px-6">
                  <router-link
                    :to="'/lessors/edit/' + lessor.id"
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >{{ lessor.name }}</router-link
                  >
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  {{ lessor.address }}
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  {{ lessor.oib }}
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  {{ lessor.email }}
                </td>
                <td
                  :class="{ 'border-top-0': key === 0 }"
                  class="text-right pr-6"
                >
                  <button
                    @click="showMoreInfoModal(lessor.id)"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/Design/Target.svg" />
                    </span>
                  </button>
                  <router-link
                    :to="'/lessors/edit/' + lessor.id"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg
                        src="media/svg/icons/General/Settings-1.svg"
                      />
                    </span>
                  </router-link>
                  <a
                    @click="deleteEntity(lessor.id)"
                    href="#"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/General/Trash.svg" />
                    </span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="mt-10 overflow-auto d-flex justify-content-center align-items-center"
          >
            <b-pagination-nav
              :link-gen="linkGen"
              :number-of-pages="pagination.totalPages"
              use-router
              size="lg"
            ></b-pagination-nav>
          </div>
        </div>
        <div v-else class="col-12 text-center p-5">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </div>
    <div class="row">
  <div class="col-12">
    <b-modal
      id="lessor_modal"
      body-class="text-left"
      centered
      size="lg"
      hide-header
      hide-footer
    >
      <div v-if="isLessorLoaded">
        <!-- Button to close modal -->
        <b-button
          class="btn btn-light-primary mb-3"
          block
          @click="$bvModal.hide('lessor_modal')"
        >
          <i class="fas fa-times"></i> Close
        </b-button>

        <!-- Lessor Info in two columns -->
        <div class="row mb-6">
          <div class="col-md-6">
            <span class="font-weight-bold">Name: </span><span>{{ lessor.name }}</span>
          </div>
          <div class="col-md-6">
            <span class="font-weight-bold">Company: </span><span>{{ lessor.company }}</span>
          </div>
        </div>

        <div class="row mb-6">
          <div class="col-md-6">
            <span class="font-weight-bold">Address: </span><span>{{ lessor.address }}</span>
          </div>
          <div class="col-md-6">
            <span class="font-weight-bold">OIB: </span><span>{{ lessor.oib }}</span>
          </div>
        </div>

        <!-- Contact Information -->
        <div class="row mb-6">
          <div class="col-md-6">
            <span class="font-weight-bold">Email: </span><span>{{ lessor.email }}</span>
          </div>
          <div class="col-md-6">
            <span class="font-weight-bold">Notification Email: </span><span>{{ lessor.meta.notificationEmail }}</span>
          </div>
        </div>

        <!-- Contract Information -->
        <div class="row mb-6">
          <div class="col-md-6">
            <span class="font-weight-bold">Contract Start Date: </span><span>{{ lessor.meta.contractStartDate }}</span>
          </div>
          <div class="col-md-6">
            <span class="font-weight-bold">Contract End Date: </span><span>{{ lessor.meta.contractEndDate }}</span>
          </div>
        </div>

        <div class="row mb-6">
          <div class="col-md-6">
            <span class="font-weight-bold">Devices Number: </span><span>{{ lessor.meta.devicesNumber }}</span>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</div>

  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'

export default {
  name: 'LessorsList',
  data() {
    return {
      lessors: [],
      isLoading: true,
      isLessorLoaded: false,
      lessor: null,
      pagination: {
        totalPages: 0,
        itemsPerPage: 30,
      },
    }
  },
  computed: {
    page() {
      return this.$route.query.page || 1
    },
  },
  mounted() {
    this.getLessors()
  },
  watch: {
    page() {
      this.getLessors()
    },
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    getLessors() {
      const currentPage = this.$route.query.page || 1
      this.isLoading = true

      ApiService.get(
        'lessors',
        `?itemsPerPage=${this.pagination.itemsPerPage}&page=${currentPage}`
      )
        .then(({ data }) => {
          this.lessors = [...data['hydra:member']]
          this.pagination.totalPages = Math.ceil(
            data['hydra:totalItems'] / this.pagination.itemsPerPage
          )
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    deleteEntity(id) {
      let confirmation = confirm('Zelite izbrisati ovaj Grad?')
      if (confirmation === false) return

      this.isLoaded = false
      ApiService.delete(`lessors/${id}`)
        .then(() => {
          // ApiService.get('lessors').then((response) => {
          //   this.lessors = [...response.data['hydra:member']]
          // })
          this.getLessors()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    showMoreInfoModal(lessorId) {
      ApiService.get('lessors/' + lessorId).then(({ data }) => {
        this.lessor = data
        this.isLessorLoaded = true
        this.$root.$emit('bv::show::modal', 'lessor_modal')
      })
    },
  },
}
</script>
